import i18next from 'i18next';
import $ from 'jquery';
import enLang from '../../locales/en.json';
import frLang from '../../locales/fr.json';

let currentLang = "fr";

i18next
    .init({
        lng: currentLang,
        debug: false,
        resources: {
            en: enLang,
            fr: frLang
        }
    })
    .then(t => {
        execTranslation(t);
    });

const toggleLanguage = () => {
    
    if (currentLang === "fr"){
    currentLang = "en";
    $("#language-toggle-button span").html("<b>FR</b>");
    }
    else {
    currentLang = "fr";
    $("#language-toggle-button span").html("<b>EN</b>");

    }
    



    i18next.changeLanguage(currentLang, (err, t) => {
        if (err) return console.error("something went wrong loading", err);

        console.log("[info] changed lang to ", currentLang);
        execTranslation(t);
    });
};

const execTranslation = t => {
    console.log("[info] translating...");
    console.log(currentLang);
    // console.log("[debug] execTranslation - ", { t });

    let langObj;
    switch (currentLang) {
        case "en":
            langObj = enLang;
            break;
        case "fr":
            langObj = frLang;
            break;
        default:
            langObj = enLang;
            break;
    }
    console.log(langObj)

    // console.log("[debug] execTranslation - ", {
    //     lang: currentLang,
    //     translation: langObj.translation
    // });

    Object.entries(langObj.translation).forEach(([key, text]) => {
        $(`#${key}`).html(t(text));
        // console.log("[debug] execTranslation - ", { key:`#${key}`, el: $(`#${key}`) });
    });
};

$(document).on('ready', () => {
    $("#language-toggle-button").on('click', toggleLanguage);
});




